<template>
  <div class="topic_Warp">
    <div class="topic"
         v-if='topic'>
      <div class="header">
        <div class="title">
          <span class="yuan"
                v-if="!$route.query.isErr">{{topic.paper_question_no}}</span>
          题目ID:
          <!-- {{rands(topic.question_id)}} -->
          {{topic.question_code}}
          <span class="fenshu">题目分数：</span>
          <span style="color:#FF644A">{{topic.question_score}}</span>
          <span class="fenshu">我的分数：</span>
          <span style="color:#FF644A">{{topic.user_score}}</span>
        </div>
        <div class="collect">
          <span v-if="discuss">
            <a-tooltip class="item"
                       effect="dark"
                       title="发帖"
                       placement="top">
              <img src="@/static/top_opertion/biji.png"
                   alt=""
                   @click="dayi"
                   title="发帖">
            </a-tooltip>
          </span>
          <span>
            <a-tooltip class="item"
                       effect="dark"
                       title="纠错"
                       placement="top">
              <img src="@/static/correctself/icon-correct-normal.png"
                   v-if="!$route.query.isErr"
                   @click="feedback"
                   alt="">
            </a-tooltip>
          </span>
          <span v-if="topic.has_favorite == 0">
            <a-tooltip class="item"
                       effect="dark"
                       title="添加到我的收藏"
                       placement="top">
              <img src="@/static/correctself/收藏.png"
                   @click="collect"
                   alt="">
            </a-tooltip>
          </span>
          <span v-else-if="topic.has_favorite == 1">
            <a-tooltip class="item"
                       effect="dark"
                       title="已收藏"
                       style="margin-right:15px"
                       placement="top">
              <img src="@/static/correctself/已收藏.png"
                   alt=""
                   @click="collect">
            </a-tooltip>
          </span>
        </div>
      </div>
      <div class="list">

        <div class="item">
          <a-collapse v-model:activeKey="activeKey"
                      expandIconPosition="right">
            <a-collapse-panel key="1"
                              header="题目内容：">
              <div class="content"
                   v-html="topic.question_content"></div>
            </a-collapse-panel>
            <a-collapse-panel key="4"
                              header="我的答案">
              <div class="content"
                   v-html="topic.user_answer"></div>
            </a-collapse-panel>

            <a-collapse-panel key="3"
                              header="题目答案">
              <div class="content"
                   v-html="topic.question_answer"></div>
            </a-collapse-panel>
            <a-collapse-panel key="2"
                              header="题目解析">
              <div class="content"
                   v-html="topic.question_explain"></div>
            </a-collapse-panel>
          </a-collapse>
        </div>
      </div>
    </div>
    <div class="evaluate"
         v-if="topic">
      <div class="defen_warp">
        <span class="span_inline_block">得分</span>
        <a-input-number v-model:value="topic.user_score"
                        :min="0"
                        style="width:27%;height:15.25%"
                        :disabled="$route.query.isCheck==4||topic.auto_judge==1"
                        :max="number"
                        label="得分" />

        <div class="submit_warp"
             v-if="!$route.query.isErr">
          <!-- $route.query.isCheck!=4 &&  -->
          <!-- <div class="text_btn_primary"
               style="paddin-right:0"
               v-if="topic.is_ignored != 0"
               @click="submitTopic('')">重新评分
          </div>
          <div v-if="$route.query.isCheck!=4 && !topic.is_ignored "
               style="padding-right:10px;display:inline-block"
               class="text_btn_primary"
               @click="submitTopic('')">
            提交
          </div> -->
          <!-- v-if="!topic.is_ignored" -->

          <!-- <div class="text_btn_danger"
               v-if="$route.query.isCheck!=4 && !topic.is_ignored"
               style="color:#FF6147;padding-right:0;display:inline-block"
               @click="submitTopic('ignore')">忽略</div> -->
        </div>
      </div>

      <div class="pingjia_warp">
        <span class="span_inline_block">自评</span>
        <a-textarea placeholder="自评"
                    :disabled="$route.query.isCheck==4"
                    style="width:81%;height:15.25%"
                    v-model:value="topic.comment_to_user" />
      </div>

      <div class="bottom_btn_warp">
        <!-- <div class="upStage"
             v-if="!route.query.isErr"
             @click="previous"> -->
        <!-- <i class="el-icon-arrow-left"></i> -->

        <!-- </div> -->
        <div class="btn1"
             v-if="!route.query.isErr"
             @click="previous">
          上一题
        </div>
        <div class="btn"
             v-if="!route.query.isErr"
             @click="add">
          下一题
        </div>

        <div class="btn submit_all"
             v-if="$route.query.isCheck!=4 "
             :style="{'margin-left':$route.query.isErr?'50px !important':''}"
             @click="submitTopic($route.query.isErr?'err':'done')">
          {{route.query.isErr?'提交':'提交整个试卷'}}
        </div>
      </div>

    </div>
    <Form ref="FormRef"
          @refresh="refresh" />
    <evaluate ref="evaluateRef" />
    <jiucuo ref="jiucuoRef" />
    <PostForm ref="postRef"
              title='发帖'
              type='1'
              @submit="submitPost"
              @cancel='cancelPost'
              :isDisable='true' />

  </div>
</template>

<script setup>
import Form from './components/index.vue'
import evaluate from './components/evaluate.vue'
import jiucuo from '@/views/analysis/components/pressDialog.vue'
import PostForm from '@/views/dayi/components/form.vue'
import { addPost } from '@/api/post.js'
import { rands } from '@/utils/rand.js'
// import jiucuo from '@/views/doPaper/components/topicPressDialog.vue'

import { ref, toRefs, createVNode, computed, defineEmits, onMounted } from 'vue'
import { Modal, message } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { delRow } from '@/api/errorBook.js'
import { correctSelf } from '@/api/myPaper.js'
import { useRouter, useRoute } from 'vue-router'
let router = useRouter()
let route = useRoute()
let discuss = ref(1)
onMounted(() => {
  discuss.value = JSON.parse(window.localStorage.getItem('userInfo')).discuss
})
const pData = defineProps({
  topic: {
    type: Object,
    default: () => {
      return {
        question_id: '',
        question_score: '',
        user_score: '',
      }
    }
  },
  topicList: {
    type: Array,
    default: () => {
      return []
    }
  }
})

let { topic, topicList } = toRefs(pData)


let index = ref(0)
let activeKey = ref([1, 2, 3, 4])
let evaluateRef = ref(null)
let number = computed(() => { return Number(topic.value.question_score) })
const emits = defineEmits(["add", "previous", "sub", "card", 'refresh',])
let FormRef = ref(null)
const add = () => {
  // if (!this.eachScore()) {
  //   return
  // }
  emits("add")
}
const previous = () => {
  emits("previous")
}
const sub = () => {
  // if (!this.eachScore()) {
  //   return
  // }
  emits("sub")
}
const submitTopic = (str = '') => {
  emits("card")


}
// const getForm = (str) => {
//   if (str == 'done') {
//     var form = {
//       submit: str,
//       user_paper_id: route.query.id,
//       correct_detail: [],
//     }

//     for (let index = 0; index < topicList.value.length; index++) {
//       let item = topicList.value[index]
//       for (let index = 0; index < item.checkPointAndScore.length; index++) {
//         let row = item.checkPointAndScore[index]
//         if (row.score === null) {
//           row.score = 0
//         }
//       }

//       for (let index = 0; index < item.knowledgePointAndScore.length; index++) {
//         let row = item.knowledgePointAndScore[index]
//         if (row.score === null) {
//           row.score = 0
//         }
//       }

//       var obj = {
//         user_paper_detail_id: item.user_paper_detail_id,
//         user_score: item.user_score,
//         comment_to_user: item.comment_to_user,
//         is_ignored: item.is_ignored,
//         checkPointAndScore: item.checkPointAndScore,
//         knowledgePointAndScore: item.knowledgePointAndScore
//       }
//       form.correct_detail.push(obj)
//     }
//   } else {
//     var form = {
//       submit: str,
//       user_paper_id: route.query.id,
//       correct_detail: [
//         {
//           user_paper_detail_id: topic.value.user_paper_detail_id,
//           user_score: topic.value.user_score,
//           comment_to_user: topic.value.comment_to_user,
//           is_ignored: topic.value.is_ignored,
//           checkPointAndScore: topic.value.checkPointAndScore,
//           knowledgePointAndScore: topic.value.knowledgePointAndScore
//         }
//       ]
//     }
//   }
//   return form
// }

const submit = async (form) => {
  // if (!eachScore() && form.str != 'ignore') {
  //   return
  // }
  const { data } = await correctSelf()
  if (form.submit == 'done') {
    evaluateRef.value.getCommont()
    evaluateRef.value.dialogVisible = true
    return
  }
  // if (form.submit == 'ignore') {
  //   this.topic.is_ignored == 1
  //   this.$parent.submitTopic()
  //   return

  // }
  // console.log('form', form)
  if (form.submit == '') {
    topic.value.is_ignored = 0
    return
  }

  emits("add")

  // })
}
let postRef = ref(null)
const dayi = () => {
  let form = {
    discuss_title: '',
    discuss_content: '',
    board_id: Number(topic.value.subject_id),
    // question_id: rands(Number(topic.value.question_id)),
    question_id: Number(topic.value.question_id),

    discuss_image: []
  }

  postRef.value.form = form
  postRef.value.dialogVisible = true

}
//发帖弹窗》提交
const submitPost = async () => {
  const { status } = await addPost(
    postRef.value.form1
  )
  if (status == 200) {
    message.success('发帖成功')
    cancelPost()
  }

}
const cancelPost = () => {
  postRef.value.dialogVisible = false
}
const collect = async () => {
  if (topic.value.has_favorite == 1) {
    // message.warning("已经收藏过了")
    Modal.confirm({
      title: '提示',
      icon: createVNode(ExclamationCircleOutlined),
      okText: '确定',
      cancelText: '取消',
      content: createVNode('div', { style: '' }, '是否确定取消收藏该题目?'),
      async onOk () {
        await delRow({ ids: topic.value.question_favorite_id })
        topic.value.question_favorite_id = 0
        topic.value.has_favorite = 0
        message.success("取消收藏成功")
      },
      onCancel () {
        return
        //console.log('Cancel');
      },
      class: '',
    });
    return
  }
  let form = {
    user_paper_detail_id: topic.value.user_paper_detail_id,
    tags: ''
  }
  FormRef.value.form = form
  FormRef.value.dialogVisible = true
}
let jiucuoRef = ref(null)
const feedback = () => {
  let form = {
    question_id: topic.value.question_id,
    user_paper_detail_id: topic.value.user_paper_detail_id,
    category: 0,
    comment: ''
  }
  jiucuoRef.value.setData(form)
  jiucuoRef.value.dialogVisible = true

}

const refresh = () => {
  emits("refresh")
}


</script>

<style lang="scss" scoped>
.topic_Warp {
  width: 100%;
  height: 100%;
  position: relative;
  box-shadow: 0px 3px 13px 5px rgba(0, 0, 0, 0.05);
  .topic {
    overflow: auto;
    height: 79%;

    .header {
      display: flex;
      background: #eff8ff;
      padding: 15px;
      .title {
        flex: 1;
        width: 80px;
        color: #666666;
        font-size: 24rem;
        .yuan {
          display: inline-block;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: #68bcff;
          color: white;
          text-align: center;
          line-height: 30px;
          margin-right: 5px;
        }
        .fenshu {
          display: inline-block;
          // font-size: 16px;
          font-weight: bold;
          color: black;
          margin-left: 20rem;
        }
      }
      .collect {
        .item {
          padding: 0;
        }
        // width: 120px;
        img {
          width: 32rem;
          margin-right: 20rem;
        }
        span {
          font-size: 20px;
        }
      }
    }
    .item {
      padding: 10rem;
      .content {
        // border: 1px solid e6e6e6;
        margin-bottom: 5px;
      }
    }
  }
  .evaluate {
    overflow: auto;
    border-top: 1px solid #d9d9d9;
    border-left: 1px solid #d9d9d9;
    margin-top: 10px;
    box-shadow: 0px 3px 13px 5px rgba(0, 0, 0, 0.05);
    position: absolute;
    background: white;
    width: 100%;
    padding-bottom: 40px;
    right: 0;
    .text_btn_primary {
      padding-left: 20rem;
      color: #3f9eff;
      cursor: pointer;
      font-size: 20rem;
    }
    .defen_warp {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 2.51%;
      span {
        margin-right: 1.04%;
        display: inline-block;
        width: 100px;
        text-align: right;
        font-size: 16px;
        font-weight: bold;
      }

      .submit_warp {
        display: inline-block;
        text-align: center;
        margin-right: 1.39%;
        ::v-deep .el-button--text {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
    .pingjia_warp {
      display: flex;
      align-items: center;
      margin-top: 2.51%;

      span {
        margin-right: 1.04%;
        display: inline-block;
        width: 112px;
        text-align: right;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .bottom_btn_warp {
      margin-top: 2.51%;
      margin-top: 2.51%;
 
      .upStage {
        cursor: pointer;
        vertical-align: bottom;
        display: inline-block;
        width: 44px;
        height: 44px;
        margin-top: 20rem;
        font-size: 20px;
        border-radius: 50%;
        margin-left: 4%;
        background-size: 100%;
        background-image: url("../../../../static/correctself/but-上一题@2x.png");
      }
      .btn1 {
        display: inline-block;
        height: 44px;
        padding: 7px 24px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: var(--radius-100_max, 100px);
        border: 1px solid var(--Brand-Color_6default, #2196f3);
        color: var(--Brand-Color_6default, #2196f3);
        text-align: center;
        background: white;
        margin-left: 40px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      
      }
      .btn {
        display: inline-block;
        color: white;
        border-radius: 30px;
        font-size: 16px;
        text-align: center;
        line-height: 44px;
        font-weight: bold;
        // padding: 0 30px;
        width: 38%;
        margin-left: 2.5%;
        margin-right: 2.5%;
        height: 44px;
        background: #3f9eff;
        cursor: pointer;
        margin-right: 187rem;
      }
      .submit_all {
        margin: 0 !important;
        text-align: center;
        line-height: 44px;
        width: 16%;
        display: inline-block;
        background: var(--f-2413-a, #F2413A);
      }
    }
  }
}

.warp {
  padding: 5px;
  font-size: 16px;
  text-indent: 1em;
  word-break: break-all;
}
.left_head {
  display: inline-block;
  border-left: 4px solid #3f9eff;
  padding-left: 15px;
  font-weight: bold;
  font-size: 16px;
}
.bottom_border {
  width: 98%;
  border-bottom: 1px solid #e5e4e4;
  height: 15px;
  margin: 0 auto;
}
::v-deep .el-form-item__label {
  color: black;
  font-size: 16px !important;
  font-weight: bold !important;
}

.span_inline_block {
  display: inline-block;
  width: 79px !important;
}
.zhishidian {
  .title {
    margin-top: 2.51%;
    margin-left: 46px;
    font-size: 16px;
    font-weight: bold;
  }
  .item {
    display: flex;
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    span {
      &:nth-child(1) {
        width: 51%;
        display: flex;
        align-items: center;
        margin-left: 46px;
      }
      &:nth-child(2) {
        text-align: right;
      }
    }
  }
}
:deep .ant-collapse {
  border: none;
  background-color: #ffffff !important;
  font-size: 20rem;
}
:deep .ant-collapse-item {
  overflow: hidden;
}

:deep .ant-collapse-header {
  background: #f6f6f6;
  border-radius: 10rem;
}
:deep .ant-collapse > .ant-collapse-item {
  border: none;
  margin-top: 20rem;
}
:deep .ant-collapse-content {
  border-top: none;
}
:deep
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  font-size: 20rem;
}
</style> 